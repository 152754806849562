import { Inject, Injectable } from '@angular/core';
import { CMSService } from '@core/cms';
import { parseFooter } from '@core/cms/api-transform/cms-footer.api-transform';
import { HttpService } from '@core/http';
import {
  InjectionTokenIsWebComponent,
  IS_WEB_COMPONENT,
} from '@core/injection-tokens';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { filter, map, share, switchMap } from 'rxjs/operators';

import { GlobalActions, RequestFooter } from '../global.actions';
import { GlobalFacade } from '../global.facade';

@Injectable()
export class GlobalEffectsFooter {
  constructor(
    private actions$: Actions,
    private globalFacade: GlobalFacade,
    private cmsService: CMSService,
    @Inject(IS_WEB_COMPONENT)
    private isWebComponent: InjectionTokenIsWebComponent,
  ) {}

  onInit$ = this.actions$.pipe(ofType(ROOT_EFFECTS_INIT), share());

  // runs only if Footer has not been requested. see requestHeaderFooter$
  requestFooter$ = this.actions$.pipe(
    ofType<RequestFooter>(GlobalActions.REQUEST_FOOTER),
    switchMap(personalizationData =>
      this.cmsService.getFooter(personalizationData?.payload),
    ),
    share(),
  );

  getFooterForWebComponentOnInit$ = createEffect(() =>
    this.onInit$.pipe(
      filter(() => this.isWebComponent === 'footer'),
      map(() => this.globalFacade.requestFooter()),
    ),
  );

  requestFooterSuccess$ = createEffect(() =>
    this.requestFooter$.pipe(
      filter(HttpService.isSuccessful),
      map(response => this.globalFacade.loadFooter(parseFooter(response))),
    ),
  );

  requestFooterSuccessRaw$ = createEffect(() =>
    this.requestFooter$.pipe(
      filter(HttpService.isSuccessful),
      map(response => this.globalFacade.loadFooterRaw(response)),
    ),
  );
}
