import { Injectable } from '@angular/core';
import { convertToDate } from '@core/utils/date.utils';
import { HotelBookingForm } from '@dynamic-components/booker-v2/bookers/hotel/booker-hotel-points/booker-hotel-points.types';
import { CITY_PAIR_CONFIG } from '@dynamic-components/booker-v2/shared/city-pair-selector/types/city-pair-selector.type';
import { SelectedTravelers } from '@dynamic-components/booker-v2/shared/traveler-selector/types';
import { BOOKER_CONFIG } from '@shared/booker/booker.config';
import { BOOKER_TRIP_TYPES } from '@shared/booker/types/booker-trip-type.enum';
import {
  extractDepartDateFromUrl,
  extractFareFromURL2,
  extractPaxFromURL,
  extractReturnDateFromUrl,
  extractTripTypeFromURL2,
  isTravellersInUrl,
} from '@shared/booker/utils';
import { waitUntil } from '@shared/rxjs-operators/wait-until.operator';
import { RecentAirSearch, RecentVacationSearch } from '@shared/types';
import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';
import { applyFilters, codesToAirports } from '@store/origins/api';
import { OriginsFacade } from '@store/origins/origins.facade';
import { Airport, CountryWithAirports } from '@store/origins/types';
import { RouterFacade } from '@store/router/router.facade';
import { pickBy, values } from 'ramda';
import { combineLatest, merge, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pluck,
  share,
  shareReplay,
  skipUntil,
  startWith,
  switchMap,
  take,
  timestamp,
  withLatestFrom,
} from 'rxjs/operators';

import { StoreService } from '../shared/store.service';
import {
  ModifyLastRecentAirSearch,
  SetRecentAirSearches,
  SetRecentHotelPtsSearch,
  SetRecentVacationSearches,
  SetSubmittedAirSearches,
  UpdateCurrentAirSearch,
} from './booker.actions';
import * as BookerSelectors from './booker.selectors';

@Injectable({ providedIn: 'root' })
export class BookerFacade {
  booker = this.storeService.getState(BookerSelectors.booker);

  // Recent Search Data comes primarily from localstorage
  recentAirSearches = this.storeService.getState(
    BookerSelectors.recentAirSearches,
  );
  recentVacationSearches = this.storeService.getState(
    BookerSelectors.recentVacationSearches,
  );
  recentHotelPtsSearch = this.storeService.getState(
    BookerSelectors.recentHotelPtsSearch,
  );
  recentDepartureDate$ = this.storeService.getState(
    BookerSelectors.recentDepartureDate,
  );
  recentReturnDate$ = this.storeService.getState(
    BookerSelectors.recentReturnDate,
  );
  recentPromoCode$ = this.storeService.getState(
    BookerSelectors.recentPromoCode,
  );
  recentOriginCode$ = this.storeService.getState(
    BookerSelectors.recentOriginCode,
  );
  recentDestinationCode$ = this.storeService.getState(
    BookerSelectors.recentDestinationCode,
  );
  recentSearchIsTrueBlue$ = this.storeService.getState(
    BookerSelectors.recentSearchIsTrueBlue,
  );
  recentTravellerCount$ = this.storeService.getState(
    BookerSelectors.recentTravellerCount,
  );
  recentLocations$ = this.storeService.getState(
    BookerSelectors.recentLocations,
  );
  recentSubmittedOWRTSearches$ = this.storeService.getState(
    BookerSelectors.recentSubmittedOWRTSearches,
  );
  bookerGlobalSettings$ = this.storeService.getState(
    BookerSelectors.bookerGlobalSettings,
  );

  recentSearchesDeDuped$ = (filterOutPartnerAirlines = false) =>
    this.storeService
      .getState(
        BookerSelectors.recentAirportsDeduplicated(filterOutPartnerAirlines),
      )
      .pipe(filter(list => !!list));

  recentTripType$ = this.storeService.getState(BookerSelectors.recentTripType);

  recentOriginFilteredOutPartner$ = combineLatest([
    this.originsFacade.airportsMap.pipe(
      filter(airportsMap => !!airportsMap),
      map(airportMap => pickBy((value: Airport) => value.jb, airportMap)),
    ),
    this.recentSearchesDeDuped$(false).pipe(
      pluck(0),
      map(recentData => recentData?.originRecentAirports?.[0]),
    ),
  ]).pipe(
    map(([partnerAirportMap, recentOrigin]) =>
      recentOrigin && partnerAirportMap[recentOrigin.code]
        ? partnerAirportMap[recentOrigin.code]
        : null,
    ),
  );

  // todo: need to figure out the correct typing here, as this can ALSO return you an array of airports, sans country wrapper
  allFilteredAirports$: Observable<CountryWithAirports[]> =
    this.storeService.getState(BookerSelectors.allAvailableAirports);

  getAllFilteredAirports$ = (
    filterOutPartner = false,
  ): Observable<CountryWithAirports[]> =>
    this.allFilteredAirports$.pipe(
      map(allFilteredAirports => {
        return applyFilters(allFilteredAirports, filterOutPartner, false, []);
      }),
    );

  // Business Logic Observables

  // todo: remove the origin that you are currently on from the destination list as well
  destinations$ = (
    currentSelectedOriginAirport$: Observable<Airport>,
    filterOutPartner = false,
    destinationBookerId = 'BOOKER_AIR',
  ) => {
    const destinationSource = this.originsFacade
      .destinationAirports(destinationBookerId)
      .pipe(
        filter(destinations => !!destinations),
        map(destinations =>
          applyFilters(destinations, filterOutPartner, false, []),
        ),
      );

    return combineLatest(currentSelectedOriginAirport$, destinationSource).pipe(
      switchMap(([currentOrigin, receivedDestinations]) => {
        return currentOrigin?.code
          ? of(receivedDestinations)
          : this.allFilteredAirports$;
      }),
    );
  };

  destinations2$ = (
    originAirportCode$: Observable<string>,
    destinationBookerId = 'BOOKER_AIR',
    filterOutPartner = false,
  ) =>
    this.originsFacade.destinationAirports(destinationBookerId).pipe(
      filter(destinations => !!destinations),
      map(destinations =>
        applyFilters(destinations, filterOutPartner, false, []),
      ),
      switchMap(destinations =>
        originAirportCode$.pipe(
          map(c => !!c),
          distinctUntilChanged(),
          switchMap(code => {
            return code
              ? of(destinations)
              : this.getAllFilteredAirports$(filterOutPartner);
          }),
        ),
      ),
    );

  currentDestination$ = (
    highPriorityDestination$: Observable<string | Airport> = of(null),
    highestPriorityDestination$: Observable<Airport | string> | null = null,
  ) => {
    const prioritizedDestination$ = highestPriorityDestination$
      ? merge(highestPriorityDestination$, highPriorityDestination$)
      : highPriorityDestination$;

    const firstEmission$ = this.destinationFromUrlThenRecent$.pipe(
      take(1),
      waitUntil(highPriorityDestination$),
      withLatestFrom(
        highPriorityDestination$,
        (initialStateVal, highPriorityDestination) => {
          return highPriorityDestination || initialStateVal;
        },
      ),
      switchMap(prioritizedDestination => {
        return highestPriorityDestination$
          ? highestPriorityDestination$.pipe(
              startWith(undefined),
              map(
                highPriorityDestination =>
                  highPriorityDestination || prioritizedDestination,
              ),
            )
          : of(prioritizedDestination);
      }),
    );

    return combineLatest([
      combineLatest([firstEmission$.pipe(take(1)), prioritizedDestination$])
        .pipe(
          map(([firstValue, subsequentValues], index) => {
            return index === 0 ? firstValue : subsequentValues;
          }),
          waitUntil(
            this.originsFacade.airportsMap.pipe(
              filter(airportMap => !!airportMap),
            ),
          ),
          switchMap((destination: Airport | string) =>
            typeof destination === 'string'
              ? this.originsFacade.getFullAirportFromCode(destination)
              : of(destination),
          ),
        )
        .pipe(timestamp()), // add timestamps to the emissions to determine which one causes the latest
      this.originsFacade.destinationAirportsMap('BOOKER_AIR').pipe(timestamp()),
    ]).pipe(
      map(
        ([
          { value: destination, timestamp: destinationTimestamp },
          { value: m, timestamp: mTimestamp },
        ]) => {
          // check that the destination is apart of the airports that origin flys to only when the emission is caused by an update to the map
          if (
            mTimestamp > destinationTimestamp && // check if map timestamp is greater than destination timestamp
            destination &&
            destination.code &&
            m &&
            !m[destination.code]
          ) {
            return null; // no apart clear destination
          }

          let transformed = [];
          if (
            !!destination &&
            !!m &&
            !destination.hasOwnProperty('interline')
          ) {
            transformed = codesToAirports(m, [destination.code]);
          }
          return transformed.length ? transformed[0] : destination;
        },
      ),
      shareReplay(1),
    );
  };

  currentOrigin$ = (
    // todo: as discussed, I'd like to make something made of more composable operators
    //  in the near future to solve problems like this, this is a stop gap until then
    filterOutPartnerAirlineAirport = false,
    highPriorityOriginCode$: Observable<string | Airport> = of(null),
    highestPriorityOrigin$: Observable<Airport | string> | null = null,
    filterOutPartnerInUrl = false,
  ) => {
    const initialOriginWithFallback$ = this.originFromUrlThenRecentThenNearby$(
      filterOutPartnerAirlineAirport,
      filterOutPartnerInUrl,
    ).pipe(
      map(stateOriginCode => {
        return (
          stateOriginCode || CITY_PAIR_CONFIG.DEFAULT_PAIR_VALUES.FROM_AIRPORT
        );
      }),
    );

    const prioritizedOrigin$ = highestPriorityOrigin$
      ? merge(
          highestPriorityOrigin$.pipe(
            skipUntil(highestPriorityOrigin$.pipe(filter(val => !!val))),
          ),
          highPriorityOriginCode$,
        )
      : highPriorityOriginCode$;

    const firstEmission$ = initialOriginWithFallback$.pipe(
      take(1),
      switchMap(initialStateVal => {
        return prioritizedOrigin$.pipe(
          map(highPriorityOrigin => highPriorityOrigin || initialStateVal),
        );
      }),
    );

    return combineLatest(firstEmission$.pipe(take(1)), prioritizedOrigin$).pipe(
      map(([firstValue, subsequentValues], index) => {
        return index === 0 ? firstValue : subsequentValues;
      }),
      waitUntil(
        this.originsFacade.airportsMap.pipe(filter(airportMap => !!airportMap)),
      ),
      switchMap((origin: Airport | string) => {
        return typeof origin === 'string'
          ? this.originsFacade.getFullAirportFromCode(origin)
          : of(origin);
      }),
      distinctUntilChanged((prev, next) => prev?.code === next?.code),
      shareReplay(1),
    );
  };

  // todo: make this into something that composes it's necessary children, but can also expose these up
  //  - eg: takes an observable for a countrymap, nearby codes, and recent - which can all be different
  // streams as long as they output the same data type
  originFromUrlThenRecentThenNearby$ = (
    filterOurPartnerInNearby = false,
    filterOutPartnerInUrl = false,
  ) => {
    const appropriateRecentOrigin$: Observable<Airport | string> =
      filterOurPartnerInNearby
        ? this.recentOriginFilteredOutPartner$
        : this.recentOriginCode$.pipe(take(1));

    const nearbyIfRecentOriginUnavailable$ = appropriateRecentOrigin$.pipe(
      switchMap(recentOrigin =>
        recentOrigin
          ? of([
              typeof recentOrigin === 'string'
                ? recentOrigin
                : recentOrigin.code,
            ])
          : this.originsFacade.nearbyAirportCodes.pipe(
              filter(result => !!result),
            ),
      ),
    );

    return combineLatest([
      this.originsFacade.airportsMap.pipe(
        filter(airportsMap => !!airportsMap),
        map(airportMap =>
          filterOurPartnerInNearby
            ? pickBy((value: Airport) => value.jb, airportMap)
            : airportMap,
        ),
      ),
      nearbyIfRecentOriginUnavailable$,
      appropriateRecentOrigin$,
    ]).pipe(
      filter(
        ([airportsByCountryMap, nearbyAirportCodes]) =>
          !!airportsByCountryMap && nearbyAirportCodes != null,
      ),
      switchMap(([airportMap, nearbyAirportCodes, recentOriginCode]) =>
        this.originParam$.pipe(
          take(1),
          map(urlOrigin => {
            const filteredUrlOrigin =
              filterOutPartnerInUrl && !airportMap[urlOrigin]
                ? null
                : urlOrigin;

            const firstFoundAirport = nearbyAirportCodes?.find(
              airportCode => airportMap[airportCode],
            );

            const airportCodeOrMAC =
              airportMap[firstFoundAirport]?.pm || firstFoundAirport;

            return filteredUrlOrigin || recentOriginCode || airportCodeOrMAC;
          }),
        ),
      ),
      share(),
    );
  };

  destinationFromUrlThenRecent$ = combineLatest([
    this.originsFacade.airportsByCountryMap,
    this.recentDestinationCode$,
  ]).pipe(
    filter(([airportsByCountryMap]) => !!airportsByCountryMap),
    map(([_, recentDestinationCode]) => recentDestinationCode),
    take(1),
    switchMap(recentDestinationCode =>
      this.destinationParam$.pipe(
        take(1),
        map(urlDestination => urlDestination || recentDestinationCode),
      ),
    ),
  );

  prioritizedDepartureDate$ = (topPriorityDepart$ = of<string | Date>(null)) =>
    this.recentDepartureDate$.pipe(
      take(1),
      switchMap(recentDepartDate =>
        this.departDateInUrl$.pipe(
          take(1),
          map(departDateUrl => departDateUrl || recentDepartDate),
        ),
      ),
      switchMap(recentDate =>
        topPriorityDepart$.pipe(
          map(departDateInput => departDateInput || recentDate),
        ),
      ),
    );

  prioritizedReturnDate$ = (topPriorityReturn$ = of<string | Date>(null)) =>
    this.recentReturnDate$.pipe(
      take(1),
      switchMap(recentDepartDate =>
        this.returnDateInUrl$.pipe(
          take(1),
          map(returnDateUrl => returnDateUrl || recentDepartDate),
        ),
      ),
      switchMap(recentDate =>
        topPriorityReturn$.pipe(
          map(returnDateInput => returnDateInput || recentDate),
        ),
      ),
    );

  prioritizedPromoCodeWithSource$ = (
    topPriorityPromoCode$ = of<string | null>(null),
  ) =>
    this.recentPromoCode$.pipe(
      take(1),
      switchMap(recentPromoCode =>
        this.promoCodeInUrl$.pipe(
          take(1),
          map(promoCodeInUrl => {
            return promoCodeInUrl
              ? { promoCode: promoCodeInUrl, source: 'url' }
              : { promoCode: recentPromoCode || '', source: 'recent' };
          }),
        ),
      ),
      switchMap(recentCodeWithSource =>
        topPriorityPromoCode$.pipe(
          map(promoCodeInput => {
            return promoCodeInput
              ? { promoCode: promoCodeInput, source: 'input' }
              : recentCodeWithSource;
          }),
        ),
      ),
    );

  prioritizedIsTrueBlue$ = (
    topPriorityIsTrueBlue$: Observable<boolean | null> = of(null),
  ): Observable<boolean> =>
    this.recentSearchIsTrueBlue$.pipe(
      take(1),
      withLatestFrom(this.routerFacade.validQueryParamsUpperCase),
      switchMap(([recentIsTrueBlue, validUpperQueryParams]) =>
        this.fareTypeInUrl$.pipe(
          take(1),
          map(fare => {
            if (fare) {
              return fare.toLowerCase() === 'points';
            } else if (Object.keys(validUpperQueryParams || {}).length !== 0) {
              return false;
            } else {
              return !!recentIsTrueBlue;
            }
          }),
        ),
      ),
      switchMap(stateDerivedIsTrueBlue =>
        topPriorityIsTrueBlue$.pipe(
          map(inputIsTrueBlue => inputIsTrueBlue ?? stateDerivedIsTrueBlue),
        ),
      ),
    );

  prioritizedTripType$ = (
    highestPriorityTripType$: Observable<BOOKER_TRIP_TYPES> = of(null),
  ) =>
    this.recentTripType$.pipe(
      take(1),
      withLatestFrom(this.routerFacade.validQueryParamsUpperCase),
      switchMap(([recentTripType, validUpperQueryParams]) =>
        this.tripTypeInUrl$.pipe(
          take(1),
          map(tripTypeInUrl => {
            if (tripTypeInUrl) {
              return tripTypeInUrl;
            } else if (Object.keys(validUpperQueryParams || {}).length !== 0) {
              return BOOKER_TRIP_TYPES.ROUNDTRIP;
            } else {
              return recentTripType || BOOKER_TRIP_TYPES.ROUNDTRIP;
            }
          }),
        ),
      ),
      switchMap(stateDerivedTripType => {
        return highestPriorityTripType$.pipe(
          map(inputTripType => inputTripType || stateDerivedTripType),
        );
      }),
    );

  defaultTravellerTopPriority = {
    topAdultPriority$: of<number>(null),
    topChildPriority$: of<number>(null),
    topInfantPriority$: of<number>(null),
    topTotalPriority$: of<SelectedTravelers>(null),
  };

  /**
    Prioritizes travellers based off of business logic and provides overrides
   **/
  prioritizedTravellerCount$ = (
    {
      topAdultPriority$,
      topChildPriority$,
      topInfantPriority$,
      topTotalPriority$,
    } = this.defaultTravellerTopPriority,
  ) =>
    this.recentTravellerCount$.pipe(
      take(1),
      switchMap(recentPassengers =>
        this.passengerParamCount$.pipe(
          take(1),
          map(passengersFromParam => {
            if (
              passengersFromParam.ADT === 1 &&
              !passengersFromParam.CHD &&
              !passengersFromParam.INF
            ) {
              return recentPassengers;
            } else {
              return passengersFromParam;
            }
          }),
        ),
      ),
      switchMap(travellers =>
        topAdultPriority$.pipe(
          map(adultTravellerInput => ({
            ...travellers,
            ADT: !isNaN(adultTravellerInput)
              ? adultTravellerInput
              : travellers.ADT,
          })),
        ),
      ),
      switchMap(travellers =>
        topChildPriority$.pipe(
          map(childTravellerInput => ({
            ...travellers,
            CHD: childTravellerInput || travellers.CHD,
          })),
        ),
      ),
      switchMap(travellers =>
        topInfantPriority$.pipe(
          map(infantTravellerInput => ({
            ...travellers,
            INF: infantTravellerInput || travellers.INF,
          })),
        ),
      ),
      switchMap(recentTravellerCounts =>
        topTotalPriority$.pipe(
          map(passengers => {
            const recentTravelers = values(recentTravellerCounts).filter(
              Boolean,
            ).length;
            if (!passengers && !!recentTravelers) {
              return {
                ADT: recentTravellerCounts.ADT,
                CHD: recentTravellerCounts.CHD,
                INF: recentTravellerCounts.INF,
              };
            } else if (passengers) {
              return {
                ADT: passengers.allTravelers.adults,
                CHD: passengers.allTravelers.children,
                INF: passengers.allTravelers.infants,
              };
            } else {
              return {
                ADT: 1,
                CHD: 0,
                INF: 0,
              };
            }
          }),
        ),
      ),
    );

  // Route Param related selectors
  originParam$ = this.routerFacade.queryParam(
    BOOKER_CONFIG.URL_PARAMETERS.ORIGIN,
  );
  destinationParam$ = this.routerFacade.queryParam(
    BOOKER_CONFIG.URL_PARAMETERS.DESTINATION,
  );
  passengerParamCount$ = this.routerFacade.queryParamsUpperCase.pipe(
    map(extractPaxFromURL),
    map(({ ADT, CHD, INF }) => ({
      // this mapping takes url params that are actually strings and converts them to numbers for the sake of later typing
      ADT: parseInt((ADT || '0') as unknown as string, 10),
      CHD: parseInt((CHD || '0') as unknown as string, 10),
      INF: parseInt((INF || '0') as unknown as string, 10),
    })),
  );

  travellersInUrl$: Observable<boolean> =
    this.routerFacade.queryParamsUpperCase.pipe(map(isTravellersInUrl));

  tripTypeInUrl$ = this.routerFacade.queryParamsUpperCase.pipe(
    map(extractTripTypeFromURL2),
  );

  urlFlightsHotelPromoCode$ = this.routerFacade.queryParamsUpperCase.pipe(
    map(params => ({ urlFlightsHotelPromoCode: params?.VACATIONPROMO })),
  );

  fareTypeInUrl$: Observable<string | null> =
    this.routerFacade.queryParamsUpperCase.pipe(map(extractFareFromURL2));
  departDateInUrl$ = this.routerFacade.queryParamsUpperCase.pipe(
    map(extractDepartDateFromUrl),
  );
  returnDateInUrl$ = this.routerFacade.queryParamsUpperCase.pipe(
    map(extractReturnDateFromUrl),
  );
  promoCodeInUrl$ = this.routerFacade.queryParam(
    BOOKER_CONFIG.URL_PARAMETERS.AIRPROMO,
  );
  openDatePickerInUrl$ = this.routerFacade
    .queryParam(BOOKER_CONFIG.URL_PARAMETERS.OPEN_DATE_PICKER)
    .pipe(map(openDatePicker => booleanStringCheck(openDatePicker)));

  /**
   * Observable to indicate whether the flights booker should use default values determined by booker URL query parameters being present
   */
  flightsBookerUseDefaults$: Observable<boolean> =
    this.routerFacade.validQueryParamsUpperCase.pipe(
      map(
        paramMap =>
          paramMap &&
          Object.keys(paramMap).length > 0 &&
          !(
            Object.keys(paramMap).length === 1 &&
            paramMap[BOOKER_CONFIG.URL_PARAMETERS.AIRPROMO]
          ),
      ), // at least one parameter in URL
    );

  flightsHotelRecentSearch$ = this.recentVacationSearches.pipe(
    map(x => x && x[0]),
    map(rs =>
      rs
        ? {
            origins: rs.originCode,
            destinations: rs.destinationCode,
            bookWithPoints: rs.fare === BOOKER_CONFIG.FARE_TYPES.POINTS,
            travelDates: {
              startDate: convertToDate(rs.departDate[0] || null),
              endDate: convertToDate(rs.returnDate[0] || null),
            },
            travelers: rs.rooms.map(room => ({
              adults: room.ADT || 0,
              children: room.CHD,
              infants: room.INF,
              childAges: room.CHDage,
            })),
          }
        : null,
    ),
  );

  allOriginsAsDestinations$ = (
    currentSelectedOriginAirport$: Observable<Airport>,
  ): Observable<CountryWithAirports[]> => {
    return combineLatest([
      this.allFilteredAirports$,
      this.destinations$(currentSelectedOriginAirport$),
    ]).pipe(
      filter(
        ([allAirports, destinations]) =>
          !!allAirports?.length && !!destinations?.length,
      ),
      map(([allAirports, destinations]) =>
        // merging all available airports and destinations by country code
        // in order to retrieve interline property from destinations and
        // assign it to a new 'all origins as destinations' object
        ((availableCountries, destinationCountries) =>
          availableCountries.reduce((all, country) => {
            let matchedCountry = destinationCountries.find(
              dest => dest.code === country.code,
            );

            if (matchedCountry) {
              matchedCountry.airports = country.airports.map(airport => {
                const matchedAirport = matchedCountry.airports.find(
                  destAirport => destAirport.code === airport.code,
                );
                return !!matchedAirport ? matchedAirport : airport;
              });
            } else {
              matchedCountry = country;
            }
            return [...all, matchedCountry];
          }, []))(allAirports, destinations),
      ),
    );
  };

  constructor(
    private storeService: StoreService,
    private originsFacade: OriginsFacade,
    private routerFacade: RouterFacade,
  ) {}

  setRecentAirSearches(recentAirSearch: RecentAirSearch) {
    this.storeService.dispatchAction(new SetRecentAirSearches(recentAirSearch));
  }

  /**
   * Copy the last recent search item and update it with the past data by spreading it over.
   */
  modifyLastRecentAirSearch(recentAirSearch: Partial<RecentAirSearch>) {
    this.storeService.dispatchAction(
      new ModifyLastRecentAirSearch(recentAirSearch),
    );
  }

  setSubmittedAirSearches(submittedAirSearch: any) {
    this.storeService.dispatchAction(
      new SetSubmittedAirSearches(submittedAirSearch),
    );
  }

  updateCurrentAirSearch(recentAirSearch: RecentAirSearch) {
    this.storeService.dispatchAction(
      new UpdateCurrentAirSearch(recentAirSearch),
    );
  }

  setRecentVacationSearches(recentVacationSearch: RecentVacationSearch) {
    this.storeService.dispatchAction(
      new SetRecentVacationSearches(recentVacationSearch),
    );
  }

  setRecentHotelPtsSearch(recentHotelPtsSearch: HotelBookingForm) {
    this.storeService.dispatchAction(
      new SetRecentHotelPtsSearch(recentHotelPtsSearch),
    );
  }
}
