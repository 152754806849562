import { AbstractControl } from '@angular/forms';
import { TypedDictionary } from '@core/utils';
import {
  convertMillisecondsToDays,
  convertToDate,
} from '@core/utils/date.utils';
import { TravelerGroup } from '@dynamic-components/booker-v2/shared/traveler-selector/types';
import { toSelectedTravelers } from '@dynamic-components/booker-v2/shared/traveler-selector/utils';
import {
  CheckBox,
  Pax,
  RadioButton,
  RecentAirport,
  RecentAirSearch,
  RecentSearch,
} from '@shared/types';
import { traverse } from '@shared/ui/utils/global-utils/traverse';
import { Airport } from '@store/origins/types';
import { format, isValid, parseISO } from 'date-fns';
import { filter, map, pick, toUpper } from 'ramda';

import { BOOKER_TRIP_TYPES } from '../types/booker-trip-type.enum';
import { BOOKER_CONFIG, BookerUrlParams } from './../booker.config';

const {
  PAX: { MAX_UNACCOMPANIED_MINORS, MAX_INFANTS, GUEST_LIMIT },
} = BOOKER_CONFIG;

export function initTBCheckbox(
  labelVal: string,
  checkedVal: boolean,
): CheckBox {
  const cb: CheckBox = {
    label: labelVal,
    checked: checkedVal,
  };
  return cb;
}

export const correctlyFormatTravellers = (
  travellers: Pax = { ADT: 1, CHD: 0, INF: 0 },
): Pax => {
  return {
    ADT: parseInt(travellers.ADT?.toString() || '0', 10),
    CHD: parseInt(travellers.CHD?.toString() || '0', 10),
    INF: parseInt(travellers.INF?.toString() || '0', 10),
  };
};

export function prePopTBCheckbox(
  recentSearch: RecentSearch,
  control: AbstractControl,
): void {
  control.setValue(recentSearch.fare === BOOKER_CONFIG.FARE_TYPES.POINTS);
  control.updateValueAndValidity();
}

export function initTripTypes(
  roundTripLabel,
  oneWayLabel,
  SEARCH_TYPES,
): RadioButton[] {
  return [
    {
      name: roundTripLabel,
      value: SEARCH_TYPES.ROUND_TRIP,
      checked: true,
    },
    {
      name: oneWayLabel,
      value: SEARCH_TYPES.ONE_WAY,
    },
  ];
}

export function initTripTypesV2(
  roundTripLabel: string,
  oneWayLabel: string,
  multiCityLabel: string,
  SEARCH_TYPES,
  currentSelectedTripType: BOOKER_TRIP_TYPES = BOOKER_TRIP_TYPES.ROUNDTRIP,
): RadioButton[] {
  return [
    {
      name: roundTripLabel,
      value: SEARCH_TYPES.ROUND_TRIP,
      checked: currentSelectedTripType === BOOKER_TRIP_TYPES.ROUNDTRIP,
    },
    {
      name: oneWayLabel,
      value: SEARCH_TYPES.ONE_WAY,
      checked: currentSelectedTripType === BOOKER_TRIP_TYPES.ONEWAY,
    },
    ...(multiCityLabel
      ? [
          {
            name: multiCityLabel,
            value: SEARCH_TYPES.MULTICITY,
            checked: currentSelectedTripType === BOOKER_TRIP_TYPES.MULTICITY,
          },
        ]
      : []),
  ];
}

/**
 * This function is used to initialize the trip types for the BFF
 * @param roundTripLabel - the label for the round trip radio button
 * @param oneWayLabel - the label for the one way radio button
 * @param SEARCH_TYPES - the search types enum
 * @returns an array of radio buttons
 */
export function initTripTypesBFF(
  roundTripLabel: string,
  oneWayLabel: string,
  SEARCH_TYPES,
): RadioButton[] {
  return [
    {
      name: roundTripLabel,
      value: SEARCH_TYPES.ROUND_TRIP,
    },
    {
      name: oneWayLabel,
      value: SEARCH_TYPES.ONE_WAY,
    },
  ];
}

export function prePopTripTypes(
  recentSearch: RecentAirSearch,
  control: AbstractControl,
  tripTypes: RadioButton[],
) {
  control.setValue(
    tripTypes.some(t => t.value === recentSearch.intineraryType)
      ? recentSearch.intineraryType
      : BOOKER_CONFIG.DEFAULT_FORM_VALUES.TRIP_TYPE,
  );
  control.updateValueAndValidity();
}

export function pluckRecentAirportVacation(
  recentSearches: RecentSearch[],
): RecentAirport[] {
  return map(pick(['originCode', 'destinationCode']), recentSearches);
}

export function pluckRecentAirport(
  recentSearches: RecentSearch[],
): RecentAirport[] {
  const nestedArray = map(
    pick(['originCode', 'destinationCode']),
    recentSearches,
  );
  const recentSearchesArray = [];
  nestedArray.forEach(elem => {
    elem.originCode
      .slice()
      .reverse()
      .forEach((origin, index) =>
        recentSearchesArray.push({
          originCode: [origin],
          destinationCode: [
            elem.destinationCode[elem.destinationCode.length - index - 1],
          ],
        }),
      );
  });
  const threeMostRecentSearches = recentSearchesArray.slice(0, 3);
  return threeMostRecentSearches;
}

export function prePopDepartureDate(
  recentSearch: RecentSearch,
  control: AbstractControl,
  formatString: string,
  isBookerV2: boolean,
): void {
  /*
   we've  included this check to prevent converting the date to a string in V2
   because it is unnecessary, as we only interact with the full date object in v2,
   and it occasionally causes bugs to go back and forth between string and date
   however, we don't want to break v1, so we are for now, adding this check
   // todo: when we no longer need to support v1, remove the else case
   */
  if (isBookerV2) {
    control.setValue(recentSearch.departDate[0]);
  } else {
    const processedDate = convertToDate(recentSearch.departDate[0]);
    control.setValue(
      isValid(processedDate) ? format(processedDate, formatString) : undefined,
    );
  }

  control.updateValueAndValidity();
}

export function prePopDepartureDatesMultiCity(
  recentSearch: RecentSearch,
  control: AbstractControl,
  rowIndex: number,
): void {
  const formattedDate =
    typeof recentSearch.departDate[rowIndex] === 'string'
      ? parseISO(recentSearch.departDate[rowIndex] as string)
      : recentSearch.departDate[rowIndex];
  control.setValue(formattedDate);
  control.updateValueAndValidity();
}

export function codesToAirports(
  airportHashMap: TypedDictionary<Airport>,
  codes: string[],
): Airport[] {
  let airports = [];
  if (!!codes && !!codes.length) {
    airports = filter(
      airport => !!airport,
      map(airportCode => airportHashMap[airportCode], codes),
    );
  }
  return airports;
}

export function prePopCityPairMultiCity(
  airportHashMap: TypedDictionary<Airport>,
  recentSearch: RecentSearch,
  controlOrigin: AbstractControl,
  controlDestination: AbstractControl,
  rowIndex: number,
): void {
  controlOrigin.setValue(
    codesToAirports(airportHashMap, [recentSearch.originCode[rowIndex]])[0],
  );
  controlDestination.setValue(
    codesToAirports(airportHashMap, [
      recentSearch.destinationCode[rowIndex],
    ])[0],
  );
}

export function prePopReturnDate(
  recentSearch: RecentSearch,
  control: AbstractControl,
  formatString: string,
  isBookerV2: boolean,
): void {
  /*
 we've included this check to prevent converting the date to a string in V2
 because it is unnecessary, as we only interact with the full date object in v2,
 and it occasionally causes bugs to go back and forth between string and date
 however, we don't want to break v1, so we are for now, adding this check
 // todo: when we no longer need to support v1, remove the else case
 */
  if (isBookerV2) {
    control.setValue(recentSearch.returnDate[0]);
  } else {
    const processedDate = convertToDate(recentSearch.returnDate[0]);
    control.setValue(
      isValid(processedDate) ? format(processedDate, formatString) : undefined,
    );
  }
  control.updateValueAndValidity();
}

export function prePopDates(
  recentSearch: RecentSearch,
  departDate: AbstractControl,
  returnDate: AbstractControl,
  formatString: string,
  isBookerV2 = false,
): void {
  prePopDepartureDate(recentSearch, departDate, formatString, isBookerV2);
  prePopReturnDate(recentSearch, returnDate, formatString, isBookerV2);
}

export function prePopTravelerSelector(
  recentSearch: RecentAirSearch,
  control: AbstractControl,
): void {
  const prePopVal = {
    ADT: !!recentSearch.ADT ? +recentSearch.ADT : 0,
    CHD: !!recentSearch.CHD ? +recentSearch.CHD : 0,
    INF: !!recentSearch.INF ? +recentSearch.INF : 0,
  };
  control.setValue(prePopVal);
  control.updateValueAndValidity();
}

/* When travelers values come from the url,
 they are arbitrarily entered by the user.
 This function is to check if the values are valid
 and they are adjusted if necessary.
*/
function validatePaxFromURL(adt, chd, inf) {
  let adults = parseFloat(adt);
  let children = parseFloat(chd);
  let infants = parseFloat(inf);

  // base case
  if (
    (isNaN(adults) || adults === 0) &&
    (isNaN(children) || children === 0) &&
    (isNaN(infants) || infants === 0)
  ) {
    adults = 1;
  } else if (isNaN(adults)) adults = 0;
  if (isNaN(children)) children = 0;
  if (isNaN(infants)) infants = 0;

  // if negative numbers, ignores the negative sign
  if (adults < 0) adults *= -1;
  if (children < 0) children *= -1;
  if (infants < 0) infants *= -1;

  if (adults + children > GUEST_LIMIT) {
    if (adults > GUEST_LIMIT) adults = GUEST_LIMIT;
    if (adults + children > GUEST_LIMIT && adults > 0) {
      children = GUEST_LIMIT - adults;
    }
  }

  // no adults, max 3 children
  if (adults === 0 && children > MAX_UNACCOMPANIED_MINORS) {
    adults = 1;
    if (adults + children > GUEST_LIMIT) children = GUEST_LIMIT - adults;
  }

  infants = Math.min(infants, MAX_INFANTS);
  if (adults === 0 && infants > 0) adults = 1;
  if (infants > adults) infants = Math.min(adults, MAX_INFANTS);

  return { adults, children, infants };
}

export function extractPaxFromURL(qparams = {}): {
  ADT: number;
  CHD: number;
  INF: number;
} {
  const urlParameters = BOOKER_CONFIG.URL_PARAMETERS;
  const adt = qparams[urlParameters.ADT];
  const chd = qparams[urlParameters.CHD];
  const inf = qparams[urlParameters.INF];

  const travelers = validatePaxFromURL(adt, chd, inf);

  return {
    ADT: travelers.adults,
    CHD: travelers.children,
    INF: travelers.infants,
  };
}

export const prePopTravelersSelector = (
  recentSearch: RecentAirSearch,
  control: AbstractControl,
) => {
  const prePopVal = {
    adults: !!recentSearch.ADT ? +recentSearch.ADT : 0,
    children: !!recentSearch.CHD ? +recentSearch.CHD : 0,
    infants: !!recentSearch.INF ? +recentSearch.INF : 0,
    childAges: [],
  };
  control.setValue(toSelectedTravelers([prePopVal]));
  control.updateValueAndValidity();
};

// todo: type the param object
export function recentSearchToUrlParam(
  recentSearch: RecentAirSearch,
): BookerUrlParams {
  return {
    ORIGIN: recentSearch.originCode[0],
    DESTINATION: recentSearch.destinationCode[0],
    ADULT:
      (recentSearch.ADT !== 1 && recentSearch.ADT?.toString()) || undefined,
    CHILD:
      (recentSearch.CHD !== 0 && recentSearch.CHD?.toString()) || undefined,
    INFANT:
      (recentSearch.INF !== 0 && recentSearch.INF?.toString()) || undefined,
    ONEWAY: recentSearch.intineraryType?.toLowerCase() === 'ow' || undefined,
    ROUNDTRIP: recentSearch.intineraryType?.toLowerCase() === 'rt' || undefined,
    MULTICITY: recentSearch.intineraryType?.toLowerCase() === 'mc' || undefined,
    FARE: recentSearch.fare,
    // @ts-ignore
    DEPARTURE: recentSearch.departDate[0]?.split('T')[0], // ts ignored because recentAirSearch says it can be date or string, but its always string
    // @ts-ignore
    RETURN: recentSearch.returnDate[0]?.split('T')[0],
  };
}

export function extractIsPointsFromURL(qparams): { fare: string } {
  const val = qparams[BOOKER_CONFIG.URL_PARAMETERS.FARE];
  return {
    fare: BOOKER_CONFIG.FARE_TYPES[
      !!val ? toUpper(val) : BOOKER_CONFIG.FARE_TYPES.CASH
    ],
  };
}

export function extractFareFromURL2(qparams = {}): string | null {
  const val = qparams[BOOKER_CONFIG.URL_PARAMETERS.FARE];
  if (val && BOOKER_CONFIG.URL_PARAMETERS_VALUES_VALIDATORS.FARE(val)) {
    return toUpper(val);
  } else {
    return null;
  }
}

export function extractDepartDateFromUrl(qparams = {}): string | null {
  return qparams[BOOKER_CONFIG.URL_PARAMETERS.DEPARTURE_DATE];
}

export function extractReturnDateFromUrl(qparams = {}): string | null {
  return qparams[BOOKER_CONFIG.URL_PARAMETERS.RETURN_DATE];
}

export function extractTripTypeFromURL(qparams = {}): {
  intineraryType: string;
} {
  const urlParameters = BOOKER_CONFIG.URL_PARAMETERS;
  return {
    intineraryType:
      qparams[urlParameters.ROUND_TRIP] === 'true'
        ? BOOKER_CONFIG.SEARCH_TYPES.ROUND_TRIP
        : BOOKER_CONFIG.SEARCH_TYPES.ONE_WAY,
  };
}

// Todo: Type the return better
export function extractTripTypeFromURL2(qparams = {}): BOOKER_TRIP_TYPES | '' {
  if (qparams[BOOKER_CONFIG.URL_PARAMETERS.ROUND_TRIP] === 'false') {
    return BOOKER_TRIP_TYPES.ONEWAY;
  } else if (qparams[BOOKER_CONFIG.URL_PARAMETERS.ROUND_TRIP] === 'true') {
    return BOOKER_TRIP_TYPES.ROUNDTRIP;
  } else {
    return null;
  }
}

/** Determine if the ADT/CHD/INF query parameter is in the url */
export function isTravellersInUrl(qparams = {}): boolean {
  return (
    !!qparams[BOOKER_CONFIG.URL_PARAMETERS.ADT] ||
    !!qparams[BOOKER_CONFIG.URL_PARAMETERS.CHD] ||
    !!qparams[BOOKER_CONFIG.URL_PARAMETERS.INF]
  );
}

export function transformDynamicBookerData(
  data,
  key:
    | 'bookerAir'
    | 'bookerHotelPoints'
    | 'bookerHotels'
    | 'bookerVacations'
    | 'bookerCars',
) {
  if (data && data[key] && data[key].length > 0) {
    return traverse(data[key][0]);
  } else {
    return {};
  }
}

export function sendBookerFSEvent(
  wdw: Window,
  tripType: string,
  bookWithPoints: boolean,
  originAirport: Airport,
  destAirport: Airport,
  departDate: Date,
  returnDate: Date,
  travelers: TravelerGroup,
  durableHomePage: boolean,
) {
  if (wdw['FS']) {
    wdw['FS'].event('Booker Air Submit', {
      trip_type_str: tripType,
      points_bool: !!bookWithPoints,
      origin_str: originAirport.code,
      origin_is_jb_bool: originAirport.jb,
      destination_str: destAirport?.code,
      destination_is_jb_bool: destAirport.jb,
      mint_bool: !!destAirport?.mint,
      seasonal_bool: !!destAirport?.seasonal,
      interline_bool: !!destAirport?.interline,
      depart_date_date: departDate,
      return_date_date: returnDate,
      days_out_int: Math.round(
        convertMillisecondsToDays(
          departDate.getTime() -
            new Date(new Date().setHours(0, 0, 0, 0)).getTime(), // today at midnight
        ),
      ),
      trip_length_int:
        tripType === BOOKER_CONFIG.SEARCH_TYPES.ONE_WAY
          ? 0
          : Math.round(
              convertMillisecondsToDays(
                returnDate.getTime() - departDate.getTime(),
              ),
            ),
      adults_int: travelers.adults,
      children_int: travelers.children,
      infants_int: travelers.infants,
      durable_home_page_bool: durableHomePage,
    });
  }
}

export function sendFlightsHotelBookerFSEvent(
  wdw: Window,
  bookWithPoints: boolean,
  origin: Airport,
  destination: Airport,
  departDate: Date,
  returnDate: Date,
  rooms: number,
  adults: number,
  children: number,
  infants: number,
) {
  if (wdw['FS']) {
    wdw['FS'].event('Booker Flight + Hotel Submit', {
      points_bool: !!bookWithPoints,
      origin_str: origin.code,
      origin_is_jb_bool: !!origin?.jb,
      destination_str: destination.code,
      destination_is_jb_bool: !!destination?.jb,
      mint_bool: !!destination?.mint,
      seasonal_bool: !!destination?.seasonal,
      interline_bool: !!destination?.interline,
      depart_date: format(departDate, 'yyyy-MM-dd'),
      return_date: format(returnDate, 'yyyy-MM-dd'),
      days_out_int: Math.round(
        convertMillisecondsToDays(
          departDate.getTime() -
            new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
        ),
      ),
      trip_length_int: Math.round(
        convertMillisecondsToDays(returnDate.getTime() - departDate.getTime()),
      ),
      total_rooms_int: rooms,
      adults_int: adults,
      children_int: children,
      infants_int: infants,
    });
  }
}

export function isOneWayTrip(tripType): boolean {
  return tripType === BOOKER_CONFIG.SEARCH_TYPES.ONE_WAY;
}

export function isRoundTrip(tripType): boolean {
  return tripType === BOOKER_CONFIG.SEARCH_TYPES.ROUND_TRIP;
}

export function isMultiCity(tripType): boolean {
  return tripType === BOOKER_CONFIG.SEARCH_TYPES.MULTICITY;
}
