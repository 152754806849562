import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EventsService } from '@core/events';
import { omit } from 'lodash';

import {
  LoggedOutBottomSectionChild,
  TBButton,
  TBLink,
  TrueBlueLoggedOutSection,
} from '../navigation';

@Component({
  selector: 'dot-tb-signed-out',
  templateUrl: './tb-signed-out.component.html',
  styleUrls: ['./tb-signed-out.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TbSignedOutComponent {
  @Input() set content(content: TrueBlueLoggedOutSection) {
    const { topSection, middleSection, bottomSection } = content;
    // jb-body-text renders links above buttons, therefore data needed to be seperated
    this.signInButton = topSection['0'][0].children[0];
    this.joinNowButton = middleSection.section[0].bodyText[0]['button'][0];
    this.benefitsLink = middleSection.section[0].bodyText[0]['link'][0];
    this.middleSection = omit(
      middleSection.section[0].bodyText[0],
      'button',
      'link',
    );
    this.bottomSection = bottomSection.section[0];
  }
  signInButton: TBButton;
  middleSection: any;
  joinNowButton: TBButton;
  benefitsLink: TBLink;
  bottomSection: LoggedOutBottomSectionChild;

  constructor(private eventsService: EventsService) {}

  signIn() {
    this.eventsService.dispatchCustomEvent(
      EventsService.CUSTOM_EVENTS.JB_HEADER_LOGIN_CLICKED_OUTPUT_EVENT,
    );
  }
}
