import { AuthProfile } from '@store/auth/types';

import { booleanStringCheck } from './boolean-string-check';

/**
 * Function that register Logs into Full Story
 * @param {string} eventName The name of the event that will be registered on FS.
 * @param {{}} eventProperties Properties set on FS for each Event.
 * @param {Window} fsWindow Injectable Window to capture and register FS logs
 */
export function registerLogFullStory(
  eventName: string,
  eventProperties = {},
  fsWindow: Window,
): void {
  if (isSSR()) {
    return;
  }

  if (fsWindow['FS']) {
    fsWindow['FS'].event(eventName, eventProperties);
  }
}

/**
 * FullStory is an asynchronous script and for some cases, we must wait it to load before trying to register a log.
 * Use this function when you need to register a log BEFORE any user interaction.
 * @param {Window} fsWindow Injectable Window to capture and register FS logs
 */
export function waitForFullStory(fsWindow: Window) {
  if (isSSR()) {
    return Promise.resolve(null);
  }
  return new Promise<void>((resolve, reject) => {
    let attempts = 0;
    const fsInterval = setInterval(() => {
      if (fsWindow['FS']) {
        clearInterval(fsInterval);
        resolve();
      } else {
        attempts++;
      }
      if (attempts > 50) {
        clearInterval(fsInterval);
        reject();
      }
    }, 250);
  });
}

export const fullstoryIdentify = (
  profile: AuthProfile,
  travelBankBalance: number,
  fsWindow: Window,
) => {
  if (typeof window === 'undefined') {
    // req for ssr
    return;
  }
  if (
    fsWindow['FS'] &&
    !!profile?.membershipid &&
    profile?.membershipid?.toLowerCase() !== 'guest'
  ) {
    const trueBlueStatus = booleanStringCheck(profile.isMosaic)
      ? profile.mosaicTier
      : 'TrueBlue';
    const pointsBalance = profile.points ? Number(profile.points) : null;

    const identifyData = {
      displayName: `${profile?.fname} ${profile?.lname}`,
      TrueBlueStatus: trueBlueStatus,
      age: profile.age,
      pointsBalance,
      travelBankBalance,
      cardType: profile?.cardStatus && profile?.cardStatus?.[0],
    };

    // TODO: DOT-12908 -> We will need to remove it after our PROD tests.
    console.log('DOTCOM FS LOGGING');
    console.log('FS Identify User uid', profile.membershipid);
    console.log('FS Identify Data', identifyData);

    fsWindow['FS'].identify(profile.membershipid, identifyData);
  }
};

/**
 * Function that Anonymize authenticated users from Full Story
 * @param {Window} fsWindow Injectable Window to capture and register FS logs
 */
export function anonymizeUserFullStory(fsWindow: Window): void {
  if (isSSR()) {
    return;
  }

  if (fsWindow['FS']) {
    fsWindow['FS'].anonymize();
  }
}

function isSSR(): boolean {
  return typeof window === 'undefined' ? true : false;
}
