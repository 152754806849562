import { Inject, Injectable } from '@angular/core';
import { CMSSitemapWrapper, INITIAL_SITEMAP } from '@core/index';
import { AuthFacade } from '@store/auth/auth.facade';
import { BookerFacade } from '@store/booker/booker.facade';
import { ItineraryFacade } from '@store/itinerary/itinerary.facade';
import { Airport } from '@store/origins/types';
import { PreferenceCenterFacade } from '@store/preference-center/preference-center.facade';
import { defer, forkJoin, Observable, of } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  skipWhile,
  take,
  timeout,
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PersonalizationService {
  private personalizationDataCache = new Map<string, Observable<any>>();

  constructor(
    private bookerFacade: BookerFacade,
    private itineraryFacade: ItineraryFacade,
    private authFacade: AuthFacade,
    private preferenceCenterFacade: PreferenceCenterFacade,
    @Inject(INITIAL_SITEMAP) private initialSitemap: CMSSitemapWrapper,
  ) {}

  // global personalization for header / footer
  getGlobalPersonalizationData(): Observable<any> {
    const homePage = this.initialSitemap.sitemap[0];
    return of({
      routeData: {
        personalization: homePage.personalization,
        personalizationTrait: homePage.personalizationTrait,
      },
    });
  }

  private getCacheKey(routeData: any, isLoggedIn: boolean): string {
    return JSON.stringify({ routeData, isLoggedIn });
  }

  getPersonalizationData(routeData: any, isLoggedIn: boolean): Observable<any> {
    const cacheKey = this.getCacheKey(routeData, isLoggedIn);

    if (this.personalizationDataCache.has(cacheKey)) {
      return this.personalizationDataCache.get(cacheKey)!;
    }

    const personalizationData$ = this.fetchPersonalizationData(
      routeData,
      isLoggedIn,
    ).pipe(shareReplay(1));

    this.personalizationDataCache.set(cacheKey, personalizationData$);

    return personalizationData$;
  }

  private fetchPersonalizationData(
    routeData: any,
    isLoggedIn: boolean,
  ): Observable<any> {
    type PersonalizationData = Airport | boolean | String | number;
    type PersonalizationObservable = Observable<PersonalizationData>;

    const personalizations =
      routeData?.personalizationTrait
        ?.split(',')
        .map((trait: string) => trait.trim()) || [];

    const targetedOrigin$: Observable<PersonalizationData> = this.bookerFacade
      .currentOrigin$(true)
      .pipe(take(1), timeout({ each: 2000, with: () => of(null) }));

    const recentSearch$: Observable<PersonalizationData> =
      this.bookerFacade.recentAirSearches.pipe(
        take(1),
        map(search => search && search.length > 0),
      );

    const searchDestination$: Observable<PersonalizationData> =
      this.bookerFacade.recentDestinationCode$.pipe(take(1));

    const hasUpcomingTrip$: Observable<PersonalizationData> = defer(() => {
      this.itineraryFacade.checkForItinerary();
      return this.itineraryFacade.itinerary.pipe(
        // skip the initial state and wait for an API response
        skipWhile(itinerary => !itinerary.hasUpdated),
        take(1),
        timeout({ each: 2000, with: () => of({ flight: [] }) }),
        map(itinerary => Boolean(itinerary && itinerary.flight.length > 0)),
      );
    });

    const hasMosaicTier$: Observable<PersonalizationData> =
      this.authFacade.authProfile.pipe(
        filter(Boolean),
        take(1),
        map(profile => profile.mosaicTier),
      );

    const pointsBalance$: Observable<PersonalizationData> =
      this.authFacade.authProfile.pipe(
        filter(Boolean),
        take(1),
        map(profile => profile.points),
      );

    const travelBankBalance$: Observable<PersonalizationData> =
      this.preferenceCenterFacade.travelBankBalance.pipe(
        filter(balance => balance != null),
        take(1),
        timeout({ each: 2000, with: () => of(0) }),
      );

    const asyncPersonalizations: {
      key: string;
      observable: Observable<PersonalizationData>;
    }[] = [
      { key: 'targetedOrigin', observable: targetedOrigin$ },
      { key: 'recentSearch', observable: recentSearch$ },
      { key: 'searchDestination', observable: searchDestination$ },
    ].concat(
      isLoggedIn
        ? [
            { key: 'hasUpcomingTrip', observable: hasUpcomingTrip$ },
            { key: 'mosaicStatus', observable: hasMosaicTier$ },
            {
              key: 'travelBankBalance',
              observable: travelBankBalance$,
            },
            {
              key: 'trueBluePointsBalance',
              observable: pointsBalance$,
            },
          ]
        : [],
    );

    const requests: {
      [key: string]: PersonalizationObservable;
    } = asyncPersonalizations
      .filter(personalizationObj =>
        personalizations.includes(personalizationObj.key),
      )
      .reduce((acc, next) => {
        return {
          ...acc,
          [next.key]: next.observable,
        };
      }, {});

    return Object.keys(requests).length > 0 ? forkJoin(requests) : of({});
  }
}
